/** @jsx jsx */
import { jsx, Spinner } from "theme-ui";
import { useCallback, useState, useEffect } from "react";
import DonationComponent from "gatsby-plugin-hfn-profile/components/DonationComponent";
import { Router } from "@reach/router";
import { validateRE } from "sites-common/utils/validations";
import PropTypes from "prop-types";
import useDonationListConf from "gatsby-plugin-hfn-profile/components/DonationComponent/hooks/useDonationListConf";
import { eventCallbackTriggers } from "../../templates/wppage";

const colorKeys = [
  "background",
  "text",
  "primary",
  "secondary",
  "errortext",
  "errorbg",
  "muted",
  "highlight",
  "background2",
];

const dfltc = `ffffff:000000:228b22:006400:ba0000:fed4d4:f6f6f6:efeffe:f0f0f0`;
const decodeColors = (colorString) => {
  const d = validateRE(
    Array.from({ length: 9 })
      .map(() => "[0-9a-fA-F]{6}")
      .join(":"),
    colorString
  )
    ? colorString
    : dfltc;
  const ret = {};

  d.split(":")
    .filter((x, xidx) => x && xidx < colorKeys.length)
    .forEach((x, xidx) => {
      ret[colorKeys[xidx]] = `#${x}`;
    });
  return ret;
};

// colortext = "000000",
// colorprimary = "228b22",
// colorsecondary = "006400",
// colorbackground = "",
// colormuted = "f6f6f6",
// colorhighlight = "efeffe",
// colorerrorbg = "fed4d4",
// colorerrortext = "ba0000",
// colorbackground2 = "f0f0f0",

const getMandatoryEnv = require("sites-common/utils/getMandatoryEnv");

const { domainDonations } = getMandatoryEnv(["domainDonations"]);

const getQp = (params) => {
  const status = params.get("status");
  const type = params.get("type");
  const txnId = params.get("txnId");
  const currency = params.get("currency");
  const amount = params.get("amount");
  const refNo = params.get("refNo");
  const trkId = params.get("trkId");
  const donationType = params.get("donationType");

  // const digioStatus = params.get("status")
  // const digioMessage = params.get("message")
  const npci_txn_id = params.get("npci_txn_id");
  const digio_doc_id = params.get("digio_doc_id");
  const message = params.get("message");

  if (status) {
    return {
      status,
      type,
      txnId,
      currency,
      amount,
      refNo,
      trkId,
      donationType,
      npci_txn_id,
      digio_doc_id,
      message,
    };
  }
  return {};
};

const getTitleTextFromId = (id, conf, dtitle) => {
  return conf[id] || dtitle.t;
};

const getProjectIdFromTitle = (title) => {
  if (title) {
    return title.trim().replace(/[' ']/g, "-").toLowerCase();
  }
  return "";
};

const EmbedCore = ({ c, d, location }) => {
  const { conf } = useDonationListConf();
  const params = new URLSearchParams(location.search);
  const status = params.get("status");
  const type = params.get("type");
  const donationType = params.get("donationType");
  const qp = getQp(params);
  const projectId = getProjectIdFromTitle(
    getTitleTextFromId(d, conf, EmbedCore.defaultProps)
  );
  const donationId = Number(d);
  const titleText = getTitleTextFromId(d, conf, EmbedCore.defaultProps);
  const initialAmount = params.get("initamount");

  const returnUrl = `${domainDonations}/embedded/${donationId}/${c}/${titleText}/${projectId}`;
  const props = {
    colors: decodeColors(c),
    donationId,
    titleText,
    projectId,
    paymentSuccessUrl: returnUrl,
    paymentFailureUrl: returnUrl,
  };

  const eventCallback = useCallback(
    (event) => eventCallbackTriggers(projectId, event),
    [projectId]
  );
  const [loading, setLoading] = useState(true);

  useEffect(() => setLoading(false), [setLoading]);

  if (loading) {
    return (
      <div sx={{ height: "100%" }}>
        <Spinner />
      </div>
    );
  }

  return (
    <DonationComponent
      {...props}
      eventCallback={eventCallback}
      qp={qp}
      status={status}
      type={type}
      donationType={donationType}
      noModal
      initialAmount={initialAmount}
    />
  );
};

EmbedCore.propTypes = {
  c: PropTypes.string,
  t: PropTypes.string,
  p: PropTypes.string,
  d: PropTypes.number,
  location: PropTypes.shape({
    search: PropTypes.string,
  }),
};

EmbedCore.defaultProps = {
  c: dfltc,
  d: 9,
  p: "heartfulness-general-fund",
  t: "Heartfulness General Fund",
  location: { search: "" },
};

const EmbedDefault = ({ location }) => {
  const { conf } = useDonationListConf();
  const { search } = location;
  const params = new URLSearchParams(search);
  const c = params.get("c");
  const d = params.get("d");
  const t = getTitleTextFromId(params.get("d"), conf, EmbedCore.defaultProps);
  const p = getProjectIdFromTitle(
    getTitleTextFromId(params.get("d"), conf, EmbedCore.defaultProps)
  );

  return (
    <EmbedCore
      c={c}
      d={d ? Number(d) || undefined : undefined}
      t={t}
      p={p}
      location={location}
    />
  );
};

EmbedDefault.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
  }),
};

EmbedDefault.defaultProps = {
  location: { search: "" },
};

const Embed = () => {
  return (
    <Router>
      <EmbedCore path="/embedded/:d/:c/:t/:p" />
      <EmbedCore path="/embedded/:d/:c/:t" />
      <EmbedCore path="/embedded/:d/:c" />
      <EmbedCore path="/embedded/:d" />
      <EmbedDefault path="/embedded" />
    </Router>
  );
};

export default Embed;
