import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import { fetchJsonIfNeeded as A } from "../../../state/actions";
import { selectorJson as S } from "../../../state/selectors";

const getMandatoryEnv = require("sites-common/utils/getMandatoryEnv");

const { donationServiceConfig } = getMandatoryEnv(["donationServiceConfig"]);

function useDonationListConf() {
  const key = "donation-list-conf";
  const dispatch = useDispatch();
  const { isLoaded, isFetching, data } = useSelector((state) => S(state, key));

  useEffect(() => {
    const { staticConf: url } = donationServiceConfig;
    if (!isFetching && !isLoaded && `${url}donation-list.json`) {
      dispatch(A(key, `${url}donation-list.json`, {}));
    }
  }, [isFetching, isLoaded, dispatch]);

  return {
    isLoaded,
    conf: isLoaded ? data : {},
  };
}

export default useDonationListConf;
